.page-contact {
    @include makeGrid(space-between, flex-start);
    overflow: hidden;
    margin: 1rem 0;
    height: 400px;
    width: 100%;

    .action-text {
        width: 90%;
        font-size: 2rem;
        font-weight: 900;
        white-space: pre-wrap;
        padding: .25em;
        @include makeGrid(center, center);  
    }

    // .page-contact--img
    &--img {
        height: 100%;
        width: 50%;
        margin-right: .5em;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url('/imgs/Group4735.png');
        background-position: center;

        > img {
            width: 100%;
        }
    }

    // .page-contact--actions
    &--actions {
        background: $dark-blue;
        color: #fff;
        width: 50%;
        height: 100%;
        margin-left: .5em;
        @include makeGrid(center, center);

        .action--contact {
            width: 100%;
            height: 50%;
            margin: 1rem;
            background-size: contain;
            text-transform: uppercase;
            background-position: center;
            background-repeat: no-repeat;
            @include makeGrid(center, center);

            &.audit {
                background-image: url('/imgs/Audit.png');
            }
            &.roadmap {
                background-image: url('/imgs/roadmap.png');
            }
            &.consultant {
                background-image: url('/imgs/Consultation2.png');
            }
            &.darkwebscan {
                background-image: url('/imgs/DarkWebScan.png');
            }
        }

        .action--download {
            width: 100%;
            height: 50%;
            margin: 1rem;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url('/imgs/PDF.png');
            @include makeGrid(center, center);

            i {
                font-size: 2rem;
                margin-right: .5rem;
            }
            .action-text {
                width: auto;
            }
        }
        

        a {
            color: #fff;
        }

        // .wrapper-right__first
        &__first {
            background: #fff url('/imgs/right-blue.png') no-repeat;
            position: absolute;
            top: 50%;
            left: 27%;
            transform: translate(-50%, -50%);
            height: 214px;
            width: 400px;

            &--blue {
                position: absolute;
                top: 33%;
                left: 11%;
            }
        }

        // .wrapper-right__second
        &__second {
            background: #fff url('/imgs/right-pink.png') no-repeat;
            position: absolute;
            top: 50%;
            left: 74%;
            transform: translate(-50%, -50%);
            height: 210px;
            width: 400px;

            &--pink {
                position: absolute;
                top: 37%;
                left: 69%;
            }
        }
        &__download {
            position: absolute;
            top: 48%;
            left: 61%;
            transform: translate(-50%, -50%);
        }

        &__small-text {
            font-size: 2rem;
            font-weight: 700;
            line-height: 1.1;
        }
        &__large-text {
            font-size: 4rem;
            font-weight: 800;
            line-height: 1;
        }
        
    }
}

//===========================
//  MEDIA QUERIES
//===========================
@media (width: 3840px) {
    .page-contact {
        height: 750px;
    }
}
@media (max-width: 1331px) {
    .page-contact .action-text {
        font-size: 1.5rem;
    }
}

@media (max-width: 1024px) {
    .page-contact--actions {
        flex-direction: column;

        .action--contact
        , .action--download {
            height: 50%;
        }
    }
}

@media (max-width: 768px) {
    .page-contact {
        flex-direction: column;
        height: auto;

        // .page-contact--img
        // .page-contact--actions
        &--img
        , &--actions {
            margin: 0;
            width: 100%;
            height: 400px;
        }

        // .page-contact--img
        &--img {
            margin-bottom: 1em;
        };

        // .page-contact--actions
        &--actions {
            flex-direction: row;

            .action--contact
            , .action--download {
                height: 50%;
            }
        }
    }
}

@media (max-width: 650px) {
    .page-contact--actions {
        flex-direction: column;

        .action--contact
        , .action--download {
            height: 35%;
        }
    }
}
