#about-us {
  background-image: url("/imgs/hero/hero_mobile.png");
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  // filter: opacity(0.7);
  padding: 10px;

  .page_container {
    margin-top: 0;
  }

  @media only screen and (max-width: 565px) {
    .dialog_title {
      font-size: 1.7rem !important;
    }
  }
  @media only screen and (max-width: 499px) {
    .team_section_wrapper {
      .owl-carousel .owl-nav button.owl-prev {
        font-size: 5rem !important;
        left: -20px;
      }
      .owl-carousel .owl-nav button.owl-next {
        font-size: 5rem !important;
        right: -20px;
      }
    }
    .team_card_container {
      width: 100%;
      display: block;
      margin: auto !important;
      pointer-events: none;
      .team_image {
        filter: none;
      }
      .team_info {
        display: block;
      }
    }
  }
  @media only screen and (max-width: 425px) {
    .team_section_wrapper {
      .owl-carousel .owl-nav button.owl-prev {
        font-size: 5rem !important;
        left: 0px;
      }
      .owl-carousel .owl-nav button.owl-next {
        font-size: 5rem !important;
        right: 0px;
      }
    }
  }
}
