#airport-page,
#construction-page,
#manufacturing-page,
#retail-page,
#medical-page,
#government-page,
#law-page,
#corporate-page,
#security-page,
#softdev-page,
#finance-page,
#about-us,
#contact-page,
#page-not-found,
#terms-page,
#page-header {
	// margin-top: 150px; /// header WITH banner && COMMENT ME IF NO HEADER BANNER .nobanner .headernobanner
	margin-top: 100px; /// header WITHOUT banner
	transition-duration: 0.5s;

	@media only screen and (max-width: 572px) {
		// margin-top: 170px; /// header WITH banner && COMMENT ME IF NO HEADER BANNER .nobanner .headernobanner
	}
	@media only screen and (max-width: 402px) {
		// margin-top: 195px; /// header WITH banner && COMMENT ME IF NO HEADER BANNER .nobanner .headernobanner
	}
}
#page-header {
	font-size: 16px;

	.wrapper {
		padding: 35px;
	}
	.title {
		color: $primary-color;
		letter-spacing: 2px;
		font-weight: 700;
		font-size: 3.5rem;
		text-align: center;
		padding: 20px 0;
	}
}
.header__wrapper {
	left: 3%;
	top: 50%;
	margin: 0;
	color: #fff;
	padding: 20px;
	line-height: 1.2;
	max-width: 900px;
	position: absolute;
	transform: translate(0, -50%);

	.header__title {
		font-size: 2.2rem;
		font-weight: 400;

		&--large {
			font-size: 4em;
			font-weight: 700;
		}
	}
	.services__title {
		font-size: 3rem;
		font-weight: 700;
		margin-bottom: 1.5rem;
	}
	.services__data {
		font-size: 2.2rem;
		font-weight: 700;
		margin-bottom: 1.5rem;
		line-height: 0;

		&--small {
			font-size: 1.5rem;
			font-weight: 600;
			margin-left: 10px;
			line-height: 1.7;
		}
	}
}

.retail__bg {
	background: #fff url('/imgs/retailbg.png') no-repeat;
	@include headerBackground;
	background-size: cover;
}
.airport__bg {
	background: #fff url('/imgs/airportbg.png') no-repeat;
	@include headerBackground;
	background-size: cover;
}
.manufacturing__bg {
	background: #fff url('/imgs/manufacturingbg.png') no-repeat;
	@include headerBackground;
	background-size: cover;
}
.construction__bg {
	background: #fff url('/imgs/constructionbg.png') no-repeat;
	@include headerBackground;
	background-size: cover;
}
.corporate__bg {
	background: #fff url('/imgs/corporatebg.png') no-repeat;
	@include headerBackground;
	background-size: cover;
}
.finance__bg {
	background: #fff url('/imgs/financebg.png') no-repeat;
	@include headerBackground;
	background-size: cover;
}
.law__bg {
	background: #fff url('/imgs/lawbg.png') no-repeat;
	@include headerBackground;
	background-size: cover;
}
.government__bg {
	background: #fff url('/imgs/governmentbg.png') no-repeat;
	@include headerBackground;
	background-size: cover;
}
.medical__bg {
	background: #fff url('/imgs/medicalbg.png') no-repeat;
	@include headerBackground;
	background-size: cover;
}
.itdepartment__bg {
	background: #fff url('/imgs/itdepartmentbg.png') no-repeat;
	@include headerBackground;
	background-size: cover;
}
.security__bg {
	background: #fff url('/imgs/securitybg.png') no-repeat;
	@include headerBackground;
	background-size: cover;
}
.softdev__bg {
	background: #fff url('/imgs/softdevbg.png') no-repeat;
	@include headerBackground;
	background-size: cover;
}

.count {
	line-height: 100px;
	color: white;
	margin-left: 30px;
	font-size: 25px;
}

.counters {
	background: #0f479a;
	color: #fff;
	padding: 40px 20px;
	border-top: 3px lightskyblue solid;
}

@media (max-width: 1600px) {
	.finance__bg,
	.retail__bg,
	.airport__bg,
	.manufacturing__bg,
	.construction__bg,
	.corporate__bg,
	.law__bg,
	.government__bg,
	.medical__bg,
	.itdepartment__bg,
	.security__bg,
	.softdev__bg {
		height: 450px;
		background-position: center;
	}
	.header__wrapper .header__title--large {
		font-size: 3.5em;
	}
	.header__wrapper .services__title {
		font-size: 2.5rem;
	}
	.header__wrapper .services__data {
		font-size: 2rem;
	}
	.header__wrapper .services__data--small {
		font-size: 1.3rem;
	}
	.header__wrapper {
		max-width: 820px;
	}
}
@media only screen and (max-width: 1366px) {
	.header__wrapper {
		left: 0;
		text-shadow: 1px 1px $primary-color;
		max-width: 700px;

		.header__title {
			font-size: 1.9rem;

			// .header__title--title
			&--large {
				font-size: 3.2em;
			}
		}
	}
}
@media only screen and (max-width: 1280px) {
	.header__wrapper {
		padding: 60px;

		.services__title {
			margin-bottom: 1rem;
		}
	}
}
@media only screen and (max-width: 1024px) {
	.finance__bg {
		background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
			url('/imgs/financebg.png');
	}
	.retail__bg {
		background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
			url('/imgs/retailbg.png');
	}
	.airport__bg {
		background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
			url('/imgs/airportbg.png') no-repeat;
	}
	.manufacturing__bg {
		background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
			url('/imgs/constructionbg.png') no-repeat;
	}
	.construction__bg {
		background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
			url('/imgs/constructionbg.png') no-repeat;
	}
	.corporate__bg {
		background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
			url('/imgs/corporatebg.png') no-repeat;
	}
	.finance__bg {
		background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
			url('/imgs/financebg.png') no-repeat;
	}
	.law__bg {
		background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
			url('/imgs/lawbg.png') no-repeat;
	}
	.government__bg {
		background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
			url('/imgs/governmentbg.png') no-repeat;
	}
	.medical__bg {
		background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
			url('/imgs/medicalbg.png') no-repeat;
	}
	.itdepartment__bg {
		background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
			url('/imgs/itdepartmentbg.png') no-repeat;
	}
	.security__bg {
		background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
			url('/imgs/securitybg.png') no-repeat;
	}
	.softdev__bg {
		background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
			url('/imgs/softdevbg.png') no-repeat;
	}
	.finance__bg,
	.retail__bg,
	.airport__bg,
	.manufacturing__bg,
	.construction__bg,
	.corporate__bg,
	.law__bg,
	.government__bg,
	.medical__bg,
	.itdepartment__bg,
	.security__bg,
	.softdev__bg {
		background-position: right !important;
		height: 550px;
	}
}
@media only screen and (max-width: 875px) {
	.header__wrapper {
		width: 600px;
		padding: 20px;
	}
}
@media (max-width: 700px) {
	.counters .container {
		grid-template-columns: repeat(2, 1fr);
	}

	.counters .container > div:nth-of-type(1),
	.counters .container > div:nth-of-type(2) {
		border-bottom: 1px lightskyblue solid;
		padding-bottom: 20px;
	}
}

@media only screen and (max-width: 600px) {
	.header__wrapper {
		width: 500px;
	}
}
@media only screen and (max-width: 500px) {
	.header__wrapper {
		width: 382px;

		.services__data {
			font-size: 2rem;

			.services__data--small {
				font-size: 1.3rem;
			}
		}
		.services__title {
			font-size: 2rem;
		}
	}

	.header__title {
		font-size: 1.5rem !important;

		&--large {
			font-size: 3em !important;
		}
	}
}
@media only screen and (max-width: 380px) {
	.header__wrapper {
		width: 313px;

		.header__title {
			font-size: 1.2rem;

			&--large {
				font-size: 2.6em;
			}
		}
	}

	.security__bg,
	.softdev__bg {
		background-position: right !important;
		height: 650px;
	}

	.itdepartment__bg {
		height: 650px;
	}

	.header__wrapper .services__data {
		line-height: 1;
	}
	.itdepartment__bg .header__wrapper {
		width: 300px;

		.services__data {
			font-size: 1.5rem;
		}
	}
	.security__bg .header__wrapper {
		width: 300px;

		.services__data {
			font-size: 1.5rem;
		}
		.services__data .services__data--small {
			font-size: 1.2rem;
		}
	}
}
