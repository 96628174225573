section {
    position: absolute;
    right: 15px;
    bottom: 0;

    #chatbox {
        max-width: 350px;
        background: #fff;
        height: 800px;
        float: right;
        display: none;
    }
    
    .chat__button {
        background: $dark-blue;
        color: #fff;
        font-size: 1rem;
        padding: 10px;
        float: right;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
    }
}
