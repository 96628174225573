@mixin makeGrid($justifyContent, $alignItems) {
  display: flex;
  align-items: $alignItems;
  justify-content: $justifyContent;
}

@mixin headerBackground {
  width: 100%;
  height: 520px;
  position: relative;
}