.footer {
  width: 100%;
  margin-top: 15px;
  color: #fff;
  background: #2e8ccd;
  padding: 1rem;
  //.footer-content
  &-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  .footer_list {
    font-size: 1rem;
    font-weight: 700;
    padding: 0;
    min-height: 245px;

    &--title {
      font-size: 1.2rem;
      font-weight: 700;
    }

    .footer_list_items {
      max-height: 245px;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      padding-top: 1.5rem;
      flex-direction: column;

      .number {
        height: 2.4rem;
        a {
          display: flex;
          width: 240px;
          &::after {
            display: none;
          }
          &:hover {
            &::after {
              opacity: 0;
            }
          }
        }
        p {
          transition: 0.5s;
          position: relative;

          &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 2px;
            top: 100%;
            left: 0;
            background: $white;
            transition: transform 0.5s;
            transform: scaleX(0);
            transform-origin: center;
          }

          &:hover {
            &::after {
              transform: scaleX(1);
              transform-origin: center;
            }
          }
          span:first-of-type {
            font-weight: 600;
          }
        }
      }
      .address {
        display: flex;
        width: 240px;
        flex-direction: column;

        span {
          font-weight: 600;
        }
      }

      li {
        list-style: none;
        font-size: 0.9rem;
        font-weight: 200;
        padding-bottom: 1rem;
        padding-right: 2rem;
        flex-wrap: wrap;
        a {
          transition: 0.5s;
          position: relative;
          text-decoration: none;
          color: #ecf0f1;
          &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 2px;
            top: 100%;
            left: 0;
            background: $white;
            transition: transform 0.5s;
            transform: scaleX(0);
            transform-origin: center;
          }

          &:hover {
            &::after {
              transform: scaleX(1);
              transform-origin: center;
            }
          }
        }
      }

      &--row {
        display: flex;
        list-style: none;
        flex-direction: row;
        max-height: 210px;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        padding-top: 1.5rem;

        li {
          list-style: none;
          font-size: 0.9rem;
          font-weight: 200;
          padding-right: 15px;
          flex-wrap: wrap;
        }
      }
    }
  }
}

.social_media img {
  width: 31px;
}

//SUPPORT INDENTION ONLY
.minus5rem-left {
  margin-left: -5rem !important;
}
@media only screen and (max-width: 1440px) {
}

@media only screen and (max-width: 1280px) {
  .footer {
    .footer_container {
      margin-bottom: 0;
      .footer-content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100% !important;
        #footer__about_us {
          min-width: 30%;
          max-width: 30%;
          margin: 1rem 0;
          padding-left: 4rem;
        }
        #footer__industries {
          min-width: 40%;
          max-width: 40%;
          margin: 1rem 0;
        }
        #footer__service {
          min-width: 30%;
          max-width: 30%;
          margin: 1rem 0;
        }
        #footer__support {
          min-width: 50%;
          max-width: 50%;
          margin: 1rem 0 !important;
          padding-left: 12rem;
          max-height: 280px;
          .footer_list {
            min-height: 280px;
          }
        }
        #footer__social_media {
          min-width: 50%;
          margin: 1rem 0;
          text-align: center;
          max-height: 165px;
          padding-right: 12rem;
          .footer_list {
            min-height: 165px;
            .footer_list_items--row {
              justify-content: center;
              li {
                padding: 0 8px;
              }
            }
          }
        }
      }
    }
  }
  .footer .footer_list .footer_list_items li {
    padding-right: 0;
  }
}

@media only screen and (max-width: 865px) {
  .footer {
    .footer_container {
      margin-bottom: 0;
      .footer-content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100% !important;
        #footer__about_us {
          min-width: 35%;
          max-width: 35%;
          margin: 1rem 0;
          padding-left: 1rem;
        }
        #footer__industries {
          min-width: 65%;
          max-width: 65%;
          margin: 1rem 0;
        }
        #footer__service {
          min-width: 35%;
          max-width: 35%;
          margin: 1rem 0;
        }
        #footer__support {
          min-width: 35%;
          max-width: 35%;
          margin: 1rem 0 !important;
          padding-left: 0;
          max-height: 220px;
          .footer_list {
            min-height: 220px;
          }
        }
        #footer__social_media {
          min-width: 30%;
          max-width: 30%;
          margin: 1rem 0;
          text-align: center;
          max-height: 165px;
          padding-right: 0;
          .footer_list {
            min-height: 165px;
            .footer_list_items--row {
              justify-content: center;
              li {
                padding: 0 8px;
              }
            }
          }
        }
      }
    }
  }
  .footer .footer_list .footer_list_items li {
    padding-right: 0;
  }
}

@media only screen and (max-width: 580px) {
  .footer {
    .footer_container {
      margin-bottom: 0;
      .footer-content {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100% !important;
        justify-content: center;
        margin: auto !important;
        #footer__about_us {
          padding-left: 0;
          max-width: 100%;
          .footer_list_items {
            flex-direction: column;
          }
        }
        #footer__industries {
          max-width: 100%;
          border-top: dashed 1px rgba(255, 255, 255, 0.3);
          padding: 1rem 0;
        }
        #footer__service {
          max-width: 100%;
          border-top: dashed 1px rgba(255, 255, 255, 0.3);
          padding: 1rem 0;
          .footer_list_items {
            flex-direction: column;
          }
        }
        #footer__support {
          max-width: 100%;
          padding-left: 0 !important;
          border-top: dashed 1px rgba(255, 255, 255, 0.3);
          padding: 1rem 0;
          .footer_list_items {
            flex-direction: column;
            li {
              margin: auto;

              .address {
                width: 100%;
              }
            }
            .number {
              a {
                width: 100%;
              }
            }
          }
        }
        #footer__social_media {
          max-width: 100%;
          padding-right: 0 !important;
          border-top: dashed 1px rgba(255, 255, 255, 0.3);
          padding: 1rem 0;
        }
      }
    }
  }
  .footer .footer_list {
    min-height: unset;
    text-align: center;
    .footer_list_items {
      flex-direction: row;
      justify-content: center;
      li {
        padding: 0.5rem 1rem;
      }
    }
  }
}

@media only screen and (max-width: 320px) {
  #footer__industries {
    min-height: 270px !important;
  }
}
