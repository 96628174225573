.notFoundPage {
  background-image: url("/imgs/404page.png");
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  padding: 10px;
  // opacity: 0.5;

  .flex_center {
    display: flex;
    justify-content: center;
    flex-direction: column;

    .logo_404 {
      position: relative;
      p {
        font-size: 20rem;
        color: $dark-blue;
        font-weight: 800;
        text-align: center;
        letter-spacing: 7rem;
        margin-left: 7rem;
      }
      img {
        position: absolute;
        width: 18rem;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    h1 {
      font-weight: 200;
      text-align: center;
    }
    a {
      background-color: $primary-color;
      color: white;
      padding: 1rem 4rem;
      border-radius: 0.5rem;
      margin: 2rem auto;
      text-decoration: none;

      &:hover {
        background: $light-blue;
      }
    }
  }
}

@media only screen and (max-width: 980px) {
  .flex_center {
    .logo_404 {
      p {
        font-size: 10rem !important;
        letter-spacing: 4rem !important;
        margin-left: 4rem !important;
      }
      img {
        width: 8rem !important;
      }
    }

    h1 {
      font-size: 1.2rem !important;
    }
    a {
      background-color: $dark-blue;
      color: white;
      padding: 0.5rem 2rem !important;
      border-radius: 0.5rem;
      margin: 2rem auto;
    }
  }
}
@media only screen and (max-width: 530px) {
  .flex_center {
    .logo_404 {
      p {
        font-size: 8rem !important;
        letter-spacing: 3rem !important;
        margin-left: 3rem !important;
      }
      img {
        width: 8rem !important;
      }
    }
  }
}
@media only screen and (max-width: 416px) {
  .flex_center {
    .logo_404 {
      p {
        font-size: 6rem !important;
        letter-spacing: 2rem !important;
        margin-left: 2rem !important;
      }
      img {
        width: 6rem !important;
      }
    }
  }
}
