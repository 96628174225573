:root {
  font-size: 16px;
}

html {
  scroll-behavior: smooth;
}

body {
  font-size: 1rem;
  overflow-x: hidden;
  font-family: "Avenir", sans-serif !important;
}

.page_container {
  width: 100%;
  max-width: 1200px;
  margin: 2rem auto;

  //.page_container-full
  &-full {
    width: 100%;
  }
}
.footer_container {
  width: 100%;
  max-width: 1400px;
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
}

.customers {
  background: $light-blue;
  padding: 0 20px;
}
.f-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-column {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.f-sa {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.f-sb {
  display: flex;
  justify-content: space-between;
}
.f-se {
  display: flex;
  justify-content: space-evenly;
}
.container-fluid {
  width: 100%;
  position: relative;
  margin: 3rem auto;
}
.item {
  width: 200px;
  height: 200px;
  margin: 50px auto;
  padding-top: 75px;
  background: #ccc;
  text-align: center;
  color: #fff;
  font-size: 3em;
}

#carousel-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1500px;
}

.container {
  margin: 10px auto;
  width: 100%;
}
main {
  position: relative;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.validation_error {
  color: $error !important;
  font-size: 1rem !important;
  font-weight: 200 !important;
}

@media only screen and (max-width: 1440px) {
  .footer_container {
    max-width: 1300px !important;
  }
}

@media only screen and (max-width: 1366px) {
  .carousel__title {
    font-size: 2rem;
  }
  .section {
    height: 600px !important;
  }
  .particles-js {
    height: 600px !important;
  }
  .key-services__title {
    text-align: center;
  }
  #key-services .content__icon img {
    width: 90%;
  }
  .contact-page {
    height: 1000px !important;
  }
  #about-us .hero-image {
    background-size: cover;
  }
  .footer_container {
    width: 96% !important;
  }
}
@media only screen and (max-width: 1024px) {
  #manufacturing-customercard #section-customercard {
    width: 84% !important;
  }

  #nav .f-center {
    width: 100%;
  }
  .contact-page .dialog {
    margin: 0 !important;
  }
}
@media only screen and (max-width: 500px) {
  .customertitle {
    width: 100%;
    padding-top: 40px !important;
    margin-top: 55px;
  }
  #section-customercard {
    width: 250px !important;
  }
  #section-customercard .owl-carousel.owl-loaded {
    .owl-carousel .owl-item img {
      width: 100%;
    }
  }
  #section-customercard .owl-carousel .owl-nav button.owl-next {
    top: -22px !important;
    right: -50px !important;
    font-size: 65px !important;
  }
  #section-customercard .owl-carousel .owl-nav button.owl-prev {
    top: -22px !important;
    left: -50px !important;
    font-size: 65px !important;
  }
}

// FOR INDUSTRIES PAGES
.industries-page,
.services-page {
  .carousel__title {
    top: 10%;
    left: 0;
    padding: 0 1em;
    transform: none;
    margin-top: 3rem;
    position: relative;
  }

  .section--withVideo {
    height: 650px;

    .particles-js {
      height: 650px !important;
    }

    @media (max-width: 1366px) {
      .particles-js {
        height: 600px !important;
      }
    }

    @media (max-width: 700px) {
      height: 450px !important;

      .particles-js {
        height: 450px !important;
      }
    }

    @media (max-width: 500px) {
      height: 350px !important;

      .particles-js {
        height: 390px !important;
      }
    }
  }

  .video_wrapper {
    // background-color: red;
    top: 19%;
    width: 100%;
    height: 400px;
    margin: auto;
    position: relative;
    @include makeGrid(center, center);

    .viewport-block,
    .viewport-block > div {
      width: auto !important;
      height: 100% !important;
      @include makeGrid(center, center);

      @media (max-width: 700px) {
        width: 100% !important;
      }
    }

    video {
      width: auto !important;
      height: 100% !important;
      border: 2px solid #ccc;
      border-radius: 4px;
      outline: none;

      @media (max-width: 700px) {
        width: 100% !important;
      }
    }

    @media (max-width: 1366px) {
      height: 380px;
    }

    @media (max-width: 700px) {
      width: 80%;
      height: 60%;
    }

    @media (max-width: 536px) {
      width: 90%;
      top: 13%;
    }
  }
}
