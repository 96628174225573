.card-wrapper {
  position: relative;

  &:hover {
    cursor: pointer;
  }
}
.card-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

}
.homecard {
  padding: 1rem 2rem;
  border-radius: 15px;
  width: 355px;
  height: 350px;
  text-align: center;
  background: #fff url('/imgs/card.png') no-repeat;
  cursor: pointer;

  // .homecard-img
  &-img {
    display: inline-block;
  }
  &-wrapper {
    @include makeGrid(center, center);
    

    >img {
      width: 140px !important;
    }
  }
  &-container {
    position: absolute;
    top: 0;
    text-align: center;
    padding: 20px;
    width: 100%;
  }


  // .card-title
  &-title {
    color: $primary-color;
    font-size: 1.2rem;
    font-weight: 700;
    margin: 0.4rem 0;
  }
  &-description {
    color: $secondary-color;
    font-size: 1.2rem;
    margin: 1rem 0;
    font-weight: 800;
  }
}
#section-customercard {
  background: $light-blue;
  margin: 10px auto;
  position: relative;
  height: 370px;
  max-width: 1500px;


    .customertitle {
      font-weight: 700;
      color: #fff;
      text-align: center;
      font-size: 2rem;
      padding-top: 70px;
    }
}

//AboutUs --- Meet the Team
.team_card_container {
  // border: 1px solid red;
  height: 270px;
  width: 140px;
  .team_image {
    width: 120px !important;
    height: 120px !important;
    margin: auto;
    filter: grayscale(1);
  }
  .team_info {
    text-align: center;
    width: 100%;
    margin-top: 1rem;
    display: none;
    .about_icon {
      &:before {
        color: #F96873;
      }
    }
    .team_name {
      text-transform: uppercase;
      font-weight: 700;
      font-size: 1.1rem;
      color: #1B5A87;
      margin-bottom: 0;
      margin-top: -.5rem;
    }
    .team_position {
      text-transform: uppercase;
      color: #2F8CCD;
      font-size: .8rem;
      font-weight: 600;

    }
  }
  &:hover {
    cursor: pointer;
    .team_image {
      filter: none;
    }
    .team_info {
      display: block;
    }
  }
}