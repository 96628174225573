
#email_sent {
    display: none;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.8);

    .modal_content {
        position: absolute;
        top:30%;
        left: 50%;
        transform: translate(-50%, 0);
        width: 480px;
        height: 280px;
        background: $primary-color;
        padding: 1rem;
        border-radius: 15px;
        .modal_close {
            display: flex;
            padding: 0;
            justify-content: flex-end;
            i {
                padding: .3rem;
                width: 30px;
                height: 30px;
            }
            i::before {
                color: #fff;
            }
        }
        .modal_image {
            height: 90px;
            width: 90px;
            display: block;
            margin: 1.5rem auto;
            margin-top:0;
        }
        .modal_msg {
            color: $white;
            font-size: 1.2rem;
            font-weight: 800;
            text-align: center;
            margin-bottom: 0;
        }
    }

    &.show_modal {
        display: block;
    }

    &.hide_modal {
        display: none;
    }

    @media only screen and (max-width: 545px) { 
        .modal_content {
            top: calc(100vh/4);
            width: 380px;
            height: calc(70vh/2);
            .modal_msg {
                font-size: 1rem;
            }
        }
    }
    @media only screen and (max-width: 425px) { 
        .modal_content {
            width: 300px;
            top: calc(100vh/3);
            height: calc(100vh/3);
        }
    }
    
    @media only screen and (max-width: 320px) { 
        .modal_content {
            width: 250px;
            height: 250px;
            .modal_image {
                margin-bottom: 2.5rem;
            }
        }
    }

}
@media only screen and (max-width: 360px) {
    #email_sent .modal_content .modal_image {
        margin: 0 auto;
    }
}