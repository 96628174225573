.zoomin {
    animation: zoomin;
    animation-duration: 5s;
}
.fromLeft {
    animation: fromLeft;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}
.fromRight {
    animation: fromRight;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}
.blur {
    animation: blur;
    animation-duration: 0.5s;
}

/* HOME PAGE ANIMATION */
@keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-moz-keyframes fadein { /* Firefox */
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-o-keyframes fadein { /* Opera */
    from {
        opacity:0;
    }
    to {
        opacity: 1;
    }
}

@-webkit-keyframes navLinkFade {
    from {
      opacity: 0;
      -webkit-transform: translateX(50px);
      transform: translateX(50px);
    }
    to {
      opacity: 1;
      -webkit-transform: translateX(0px);
      transform: translateX(0px);
    }
  }
  
  @keyframes navLinkFade {
    from {
      opacity: 0;
      -webkit-transform: translateX(50px);
      transform: translateX(50px);
    }
    to {
      opacity: 1;
      -webkit-transform: translateX(0px);
      transform: translateX(0px);
    }
  }
  
  .toggle .line1 {
    -webkit-transform: rotate(-45deg) translate(-5px, 6px);
    transform: rotate(-45deg) translate(-5px, 6px);
  }
  
  .toggle .line2 {
    opacity: 0;
  }
  
  .toggle .line3 {
    -webkit-transform: rotate(45deg) translate(-5px, -6px);
    transform: rotate(45deg) translate(-5px, -6px);
  }
  /*# sourceMappingURL=styles.css.map */
  

/* RETAIL PAGE ANIMATION */
@keyframes strikethrough {
    0% {
        width: 100%;
    }
    30% {
        width: 100%;
    }
    100% {
        width: 0;
    }
}
@keyframes strikethrough2 {
    0% {
        width: 0%;
    }
    50% {
        width: 0%;
    }
    100% {
        width: 40%;
    }
}

@keyframes leftContent {
    0% { transform: translateX(-800px ) }
    100% { transform: translateX(0) }
}
  @keyframes rightContent {
    0% { transform: translateX(800px ) }
    100% { transform: translateX(0) }
}

@keyframes fromLeft {
    0% { transform: translateX(-800px) }
    100% {transform: translateX(0)}
}
@keyframes fromRight {
    0% { transform: translateX(800px) }
    100% {transform: translateX(0)}
}

@keyframes navline {
    0% {width: 0%}
    100% {width: 100%}
}


//=== PAGE HEADER ANIMATION === //
@keyframes blur {
    0% {filter: blur(0px)}
    100% {filter: blur(8px)}
}

@keyframes zoomin {
    0% {transform: scale(1)}
    100% {transform: scale(1.5)}
}