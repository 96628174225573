.owl-carousel {
    position: absolute;
    top: 46%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 78% !important;

    .active {
        background: none;
        
        &:before {
            border-bottom: none;
            
        }
    }

    .owl-nav button.owl-prev {
    position: absolute;
    top: 20%;
    left: -45px;
    color: #fff !important;
    font-size: 90px !important;

        &:hover,
        &:active,
        &:focus {
            background: none;
            outline: none;
        }
    }
    .owl-nav button.owl-next {
        position: absolute;
        top: 20%;
        right: -45px;
        color: #fff !important;
        font-size: 90px !important;

        &:hover,
        &:active,
        &:focus {
            background: none;
            outline: none;
        }
    }
}

#section-customercard {


    .owl-carousel .owl-nav button.owl-prev {
        position: absolute;
        margin: 0;
        top: -12px;
        left: -45px;
        color: #fff !important;
        font-size: 80px !important;

            &:hover,
            &:active,
            &:focus {
                background: none;
                outline: none;
            }
    }
    .owl-carousel .owl-nav button.owl-next {
        position: absolute;
        margin: 0;
        top: -12px;
        right: -45px;
        color: #fff !important;
        font-size: 80px !important;

        &:hover,
        &:active,
        &:focus {
            background: none;
            outline: none;
        }
    }
    .owl-carousel.owl-loaded {
        position: absolute;
        top: 63%;
    }
    .owl-nav {
        display: block;
    }
}

//MEET THE TEAM
.team_carousel_wrapper{
    top: 160px;
    width: 90%;
}

#law-customercard,
#finance-customercard,
#airport-customercard,
#gov-customercard,
#construction-customercard,
#medical-customercard,
#it-customercard,
#security-customercard,
#softdev-customercard {
    background: $light-blue;

    .owl-carousel.owl-loaded {
        top: 60%;
    }
}
#manufacturing-customercard,
#corporate-customercard,
#retail-customercard {
    background: $light-blue;
}

main #section-customercard {
    background: $light-blue;
}

.dialog-transparent {

    .owl-carousel {
        position: relative !important;
        width: 90% !important;

        .owl-nav .owl-prev {
            color: #9c9c9c !important;
            top: 0;
        }
        .owl-nav .owl-next {
            color: #9c9c9c !important;
            top: 0;
        }
    }
}
#law-page #section-customercard .owl-nav { 
    display: none;

}


// ========= MEDIA QUERIES =========== //

@media (width: 3840px) {

    .homecard-wrapper > img {
        width: 200px;
    }
    .homecard-title {
        font-size: 2rem;
    }
    .homecard-description {
        font-size: 1.8rem;
    }
}
@media only screen and (max-width: 1980px) {
    .section .owl-carousel {
      width: 80% !important;
    }
    .homecard {
        width: 355px;
    }
      .homecard-description {
        font-size: 1rem;
    }
}
@media only screen and (max-width: 1920px) {
    .section .owl-carousel {
      width: 80% !important;
    }
    .homecard {
        width: 355px;
    }
      .homecard-description {
        font-size: 1rem;
    }
}
@media only screen and (max-width: 1780px) {

    .homecard-wrapper > img {
        width: 120px !important;
    }
    .homecard-title {
        font-size: 1.1rem;
    }
    .homecard-description {
        font-size: 1rem;
    }
}
@media only screen and (max-width: 1708px) {

    .homecard-wrapper > img {
        width: 120px;
    }
    .homecard-title {
        font-size: 1.1rem;
    }
    .homecard-description {
        font-size: 1rem;
    }
}
@media only screen and (max-width: 1680px) {
    .homecard-wrapper > img {
        width: 110px !important;
    }
    .homecard-title {
        font-size: 1.1rem;
    }
    .homecard-description {
        font-size: 0.9rem;
    }
}
@media only screen and (max-width: 1568px) {

    .homecard-wrapper > img {
        width: 100px;
    }
    .homecard-title {
        font-size: 1.1rem;
    }
    .homecard-description {
        font-size: 0.9rem;
    }
}
@media only screen and (max-width: 1536px) {

    .homecard-wrapper > img {
        width: 100px !important;
    }
    .homecard-title {
        font-size: 0.9rem;
    }
    .homecard-description {
        font-size: 0.8rem;
    }
}
@media only screen and (max-width: 1500px) {

    .homecard-title {
        font-size: 0.9rem;
    }
    .homecard-description {
        font-size: 0.8rem;
    }
}
@media only screen and (max-width: 1464px) {

    .homecard-wrapper > img {
        width: 90px;
    }
    .homecard-title {
        font-size: 0.9rem;
    }
    .homecard-description {
        font-size: 0.8rem;
    }
}
@media only screen and (max-width: 1440px) {

    .homecard-description {
        font-size: 0.8rem;
    }
    .homecard-title {
        font-size: 0.8rem;
    }
    .homecard-wrapper > img {
        width: 90px;
    }
}
@media only screen and (max-width: 1399px) {

    .homecard-wrapper > img {
        width: 110px !important;
    }
    .homecard-description {
        font-size: 1rem;
    }
    .homecard-title {
        font-size: 1.1rem;
    }
}
@media only screen and (max-width: 1384px) {

    .homecard-title {
        font-size: 1.1rem;
    }
    .homecard-description {
        font-size: 1rem;
    }
}
@media only screen and (max-width: 1300px) {

    .homecard-wrapper > img {
        width: 80px !important;
    }
    .homecard-title {
        font-size: 1rem;
    }
    .homecard-description {
        font-size: 0.8rem ;
    }
}
@media only screen and (max-width: 1024px) {
    .carousel__title {
        font-size: 1.5rem;
    }
    .section .owl-carousel {
        width: 80%;
    }
    .homecard-title {
        font-size: 0.9rem;
    }
    .homecard-description {
        font-size: 0.8rem ;
    }
    
}
@media (max-width: 1023px) {
    #section-customercard .owl-carousel .owl-nav button.owl-next,
    #section-customercard .owl-carousel .owl-nav button.owl-prev {
        top: 10%;
    }
}
@media only screen and (max-width: 1116px) {
    .carousel__title {
        font-size: 1.5rem;
    }
    .section .owl-carousel {
        width: 80% !important;
    }
}
@media only screen and (max-width: 1000px) {
    #law-page #section-customercard .owl-nav { 
        display: block;
    
    }
    .carousel__title {
        font-size: 1.5rem;
    }
    .section .owl-carousel {
        width: 80% !important;
    }
    .homecard-wrapper > img {
        width: 90px;
    }
    .homecard-title {
        font-size: 0.8rem;
    }
    .homecard-description {
        font-size: 0.7rem;
    }
}
@media only screen and (max-width: 982px) {

    .section .owl-carousel {
        width: 70% !important;
    }

    .homecard-wrapper > img {
        width: 120px !important;
    }
    .homecard-title {
        font-size: 1.1rem;
    }
    .homecard-description {
        font-size: 1rem ;
    }
}
@media only screen and (max-width: 964px) {

    .homecard-wrapper > img {
        width: 150px;
    }
    .homecard-title {
        font-size: 1.1rem;
    }
    .homecard-description {
        font-size: 1rem ;
    }
}
@media only screen and (max-width: 917px) {

    .homecard-wrapper > img {
        width: 100px !important;
    }
    .homecard-title {
        font-size: 0.9rem;
    }
    .homecard-description {
        font-size: 0.8rem;
    }
    #section-customercard .owl-carousel .owl-nav button.owl-next,
    #section-customercard .owl-carousel .owl-nav button.owl-prev {
        top: 5%;
    }
}
@media only screen and (max-width: 768px) {
    .homecard-wrapper > img {
        width: 90px !important;
    }
    .homecard-title {
        font-size: 0.9rem;
    }
    .homecard-description {
        font-size: 0.8rem;
    }
}
@media only screen and (max-width: 762px) {
    .homecard-wrapper > img {
        width: 80px !important;
    }
    .homecard-title {
        font-size: 0.8rem;
    }
    .homecard-description {
        font-size: 0.7rem;
    }
}
@media only screen and (max-width: 762px) {

    .homecard-title {
        font-size: 0.7rem;
    }
    .homecard-description {
        font-size: 0.6rem;
    }
}
@media only screen and (max-width: 617px) {

    .homecard-wrapper > img {
        width: 70px !important;
    }
    .homecard-title {
        font-size: 0.6rem;
    }
    .homecard-description {
        font-size: 0.5rem;
    }
}
@media only screen and (max-width: 600px) {
    .homecard-wrapper > img {
        width: 60px;
    }
    .homecard-title {
        font-size: 0.6rem;
    }
    .homecard-description {
        font-size: 0.5rem;
    }
}
@media only screen and (max-width: 599px) {
    .section .owl-carousel {
        width: 60% !important;
    }
    .homecard-wrapper > img {
        width: 120px !important;
    }
    .homecard-title {
        font-size: 1.2rem;
    }
    .homecard-description {
        font-size: 1rem;
    }
}
@media only screen and (max-width: 540px) {
    .homecard-wrapper > img {
        width: 100px !important;
    }
    .homecard-title {
        font-size: 0.9rem;
    }
    .homecard-description {
        font-size: 0.8rem ;
    }
}
@media only screen and (max-width: 500px) {
    
    .section .owl-carousel {
        width: 290px !important;
    }
    .homecard-wrapper > img {
        width: 90px !important;
    }
    .homecard-title {
        font-size: 1rem;
    }
    .homecard-description {
        font-size: 0.9rem;
    }
}
@media only screen and (max-width: 370px) {
    .section .owl-carousel {
        width: 254px !important;
    }
    .section .owl-carousel .owl-nav button.owl-prev {
        left: -45px;
    }
    .section .owl-carousel .owl-nav button.owl-next {
        right: -45px;
    }
    .homecard-wrapper > img {
        width: 80px !important;
    }
    .homecard-title {
        font-size: 0.9rem;
    }
    .homecard-description {
        font-size: 0.8rem;
    }
    
}