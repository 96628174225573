#preloader_wrapper {
  display: block;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.8);
  .preloader {
      display: block;
      position: relative;
      left:50%;
      top:50%;
      width:100px;
      height:100px;
      margin:-100px 0 0 -100px;
      border-top:3px solid $primary-color;
      border-radius:50%;
      -webkit-animation: spin 2.5s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
              animation: spin 2.5s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
    }

    .preloader:before{
      content:"";
      position:absolute;
      top:5px;
      left:5px;
      bottom:5px;
      right:5px;
      border-top: 3px solid $light-blue;
      border-radius:50%;
      -webkit-animation: spin 2s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
              animation: spin 2s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
    }

    .preloader:after{
      content:"";
      position:absolute;
      top:15px;
      left:15px;
      bottom:15px;
      right:15px;
      border-top: 3px solid #fff;
      border-radius:50%;

      -webkit-animation: spin 2.5s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
              animation: spin 2.5s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
    }

    @-webkit-keyframes spin {
      0%   {
        -webkit-transform: rotate(0deg);  /* Chrome, Opera 15+, Safari 3.1+ */
          -ms-transform: rotate(0deg);  /* IE 9 */
              transform: rotate(0deg);  /* Firefox 16+, IE 10+, Opera */
        }
      100% {
        -webkit-transform: rotate(360deg);  /* Chrome, Opera 15+, Safari 3.1+ */
          -ms-transform: rotate(360deg);  /* IE 9 */
              transform: rotate(360deg);  /* Firefox 16+, IE 10+, Opera */
      }
    }
    @keyframes spin {
      0%   {
        -webkit-transform: rotate(0deg);  /* Chrome, Opera 15+, Safari 3.1+ */
          -ms-transform: rotate(0deg);  /* IE 9 */
              transform: rotate(0deg);  /* Firefox 16+, IE 10+, Opera */
        }
      100% {
        -webkit-transform: rotate(360deg);  /* Chrome, Opera 15+, Safari 3.1+ */
          -ms-transform: rotate(360deg);  /* IE 9 */
              transform: rotate(360deg);  /* Firefox 16+, IE 10+, Opera */
      }
    }
}

@media (max-width: 425px) {
  #preloader_wrapper {

    .preloader {
      display: block;
      position: relative;
      left:60%;
      top:50%;
      width:100px;
      height:100px;
      margin:-100px 0 0 -100px;
      border-top:3px solid $primary-color;
      border-radius:50%;
      -webkit-animation: spin 2.5s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
              animation: spin 2.5s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
    }
  }
}