.dialog {
    padding: 1.5rem 2.5rem;
    border-radius: 1rem;
    margin: 2.5rem;
    // .dialog-transparent
    &-transparent {
        background-color: rgba($color: #fff, $alpha: 0.7);
    }
}
.absolute_centered {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
}

.dialog_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem;
}

.dialog_title {
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
    padding: .5rem;
    color: $secondary-color;
    &-blue {
        color: $primary-color;
    }
}

.dialog_description {
    font-size: 1rem;
    text-align: center;
    line-height: 1.7rem;
    color: $secondary-color;
}

.contact_image {
    height: 300px;
    width: 300px;
    margin: auto;
}