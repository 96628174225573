
.particles-js {
    width: 100%;
    background-color: #235691;
    background-image: url("/imgs/Group4478.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: inherit;
    position: absolute;
    height: 800px;

    .particle-canvas {
        position: absolute;
        width: 100%;
        z-index: 1;
        margin: 0 auto;
        left: 50%;
        top: 50%;
        text-align: center;
        transform: translate(-50%, -50%);
    }
    .img-wrapper {
        margin-top: 3rem;
    }
    
}
.carousel__title {
    font-weight: 700;
    text-align: center;
    color: #fff;
    position: absolute;
    top: 14%;
    left: 50%;
    transform: translate(-50%, -50%);

    &--blue {
        color: #4bdfff;
    }
}
.section {
    position: relative;
    width: 100%;
    margin: 15px 0;
    height: 800px;

    // .section-content 
    &-content {
        @include makeGrid(center, center);

        // .section-content--absolute 
        &--absolute {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            @include makeGrid(center, center)
        }
    }
    .slick-slider {
        top: 25%;
    }

    .radio {
        position: absolute;
        bottom: 6%;
        left: 50%;
        z-index: 2;
        display: flex;
        margin: 0 auto;
        transform: translate(-50%, -50%);

        label {
            margin: 0 10px;
            color: #fff;
        }

        #submit {
            padding: 10px 50px;
            color: #fff;
            background: $green;
            border: none;
            border-radius: 8px;
            margin-top: 20px;

            &:disabled {
                cursor: not-allowed;
            }
        }
    }
}

@media screen and (max-width: 1366px) {
    .carousel__title {
      font-size: 2rem;
    }

    .section {
      height: 600px !important;
    }

    .section .radio {
        bottom: 2% !important;
        width: 100%;
    }
}

@media only screen and (max-width: 800px) {

    #section-customercard .owl-carousel {
        width: 80% !important;
    }
}