// THEME COLOR
$primary-color: #137cc4;
$secondary-color: #353d52;
$light-blue: #4bc3db;
$dark-blue: #235691;
$indigo: #2b3d90;
$error: #f44336;
$green: #f96973;
$pink: #f96973;
$turkish-blue: #72c2d1;

// NEUTRAL COLOR
$black: #000;
$white: #fff;