#key-services {
    @include makeGrid(center, center);
    gap: 1em;
    margin: 6rem 0;

    @media (max-width: 500px) {
        margin: 4rem 0;
    }

    .content__icon {
        justify-content: center;
        position: relative;
        margin-right: 40px;

    }

    .key-services__title {
        font-size: 2.5rem;
        color: $secondary-color;
        font-weight: 700;
        margin: 25px 0; 
        position: relative;
        animation-fill-mode: forwards;
    }
    .key-services__ul {
        position: relative;
        padding: 0;

        .key-services__li {
            list-style-type: none;
            font-size: 1.2rem;
            color: $secondary-color;
            align-items: center;
            margin-left: 25px;

            // key-services__li--img
            &--img {
                width: 50px;
                height: 50px;
                margin-top: 5px;
                margin-right: 15px;
            }
        }
    }
}
.icon {
    font-size: 2rem;
    color: $light-blue;
    list-style: none;
    display: flex;
}
.line-space {
    @include makeGrid(flex-start, flex-start);
    margin: 10px 0;
}
@media only scree and (max-width: 1024px) {
    .key-services__title {
        text-align: center;
    }
    #key-services .key-services__title {
        font-size: 2rem !important;
    }
      #key-services .key-services__ul .key-services__li {
        font-size: 1rem !important;;
    }
      #key-services .content__icon img {
        width: 70%;
    }
}
@media  only screen and (max-width: 800px) {
    #key-services {
        flex-direction: column;
        
    }
    #key-services .key-services__ul {
        padding: 10px;
    }
    #key-services .content__icon {
        margin-right: 0px;
    }
}