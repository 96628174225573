.row {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -1rem;
	align-items: flex-start;
	flex-direction: row;
}

$breakpoints: ('xl':1200px, 'lg':1024px, 'md':768px, 'sm':576px);

.col {
	@for $i from 12 through 1 {
		// e.g. col-12
		&-#{$i} {
			max-width: percentage($i/12);
		}

		@each $break, $screen in $breakpoints {
			@media (min-width: $screen) {
				// e.g. col-md-6
				&-#{$break}-#{$i} {
					max-width: percentage($i/12);
				}
			}
		}
	}
}

[class*='col-'] {
	flex-grow: 1;
	flex-shrink: 1;
	width: 100%;
	padding: 0 1rem;
	margin: 1rem auto;
	flex-direction: column;
	align-items:flex-start;
}