#terms-page {
  a {
    font-weight: 200;
  }
  b {
    font-weight: 700;
  }
  ol,
  ul {
    padding-bottom: 0.8rem;
    font-weight: 200;
    li {
      color: $secondary-color;
      font-size: 1rem;
      font-weight: 200;
    }
  }

  .dark-blue {
    background: $turkish-blue;
  }
  .terms__title {
    font-weight: 700;
    color: #fff;
    text-align: center;
    padding: 1.5rem;
    text-transform: uppercase;
  }
  .under_title_justify {
    text-align: justify;
  }
  .under_title_text {
    color: #353d52;
    text-align: center;
    margin-top: -1.8rem;
    font-size: 1.2rem;
  }
  .terms__ul {
    text-decoration: none;
    margin: 0;
    padding: 0;

    li {
      list-style: none;
      display: flex;
      align-items: center;
      i {
        display: flex;
        margin-right: 10px;
      }
    }
  }
  .terms__description {
    max-width: 1150px;
    color: $secondary-color;
    font-size: 1em;
    text-align: center;
    padding: 2rem;
    margin: 0 auto;
    font-weight: 200;

    &--padding-small {
      color: $secondary-color;
      font-size: 1em;
      text-align: center;
      padding: 1rem 0;
      margin: 0 auto;
      font-weight: 200;
    }
    &--left-align {
      color: $secondary-color;
      font-size: 1em;
      text-align: start;
      padding: 1rem 0;
      margin: 0 auto;
      font-weight: 200;
    }
  }
  .terms__container {
    max-width: 1150px;
    display: flex;
    padding: 0 3rem;
    justify-content: space-between;
    align-items: flex-start;
    margin: 4rem auto;

    // .terms__container--div
    &--left {
      @include makeGrid(space-between, flex-start);
      flex-direction: column;
    }
    &--right {
      @include makeGrid(space-between, flex-start);
      flex-direction: column;
    }
  }
  .service__title {
    font-size: 1.7rem;
    font-weight: 700;
    color: $secondary-color;

    &--center {
      text-align: center;
      font-size: 1.7rem;
      font-weight: 700;
      color: $secondary-color;
      margin: 2rem auto;
    }
    &--medium {
      font-size: 1.5rem;
      font-weight: 700;
      color: $secondary-color;
      margin: 2rem auto;
    }
    &--small {
      font-size: 1.2rem;
      font-weight: 700;
      color: $secondary-color;
      margin: 2rem auto;
    }
  }
  .service__description {
    color: $secondary-color;
    font-size: 1em;
    text-align: start;
    margin: 35px;
    font-weight: 200;
  }
  .terms_table {
    margin: 2rem 0;
    width: 100%;
    th {
      text-transform: uppercase;
    }
    tr {
      border-bottom: solid 1px rgba(0, 0, 0, 0.1);
    }
    td,
    th {
      padding: 0.2rem 1rem;
      // &:nth-child(2) {
      //     border-left: solid 1px rgba(0,0,0, 0.1);
      //     border-right: solid 1px rgba(0,0,0, 0.1);
      // }
    }
  }
  p {
    color: $secondary-color;
    font-size: 1rem;
    font-weight: 200;
  }

  #section2 {
    text-align: center;
  }

  .container-divider {
    margin: 50px 0;

    ul {
      @include makeGrid(center, center);
      li {
        font-size: 2rem;
        color: #4bc3db;
        list-style: none;
        display: flex;
      }
    }
  }
  .less_than_Ten {
    column-count: 1 !important;
  }
  .list-container {
    .colored_ordered_list {
      counter-reset: item;
      column-count: 2;
      column-gap: 40px;
      margin: 0;
      padding: 1rem 0;

      li {
        display: flex;
        align-items: center;
        counter-increment: item;
        list-style: none inside;
        margin: 5px 0;
        overflow: hidden;
        font-size: 0.95rem !important;
        line-height: 1.3;
        color: $secondary-color;

        &:before {
          content: counter(item);
          margin-right: 20px;
          padding: 6px;
          display: block;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
          width: 30px;
          background: $light-blue;
          color: #fff;
          text-align: center;
          font: 1rem "Lato", Helvetica, Arial, sans-serif;
          font-weight: 700;
          float: left;
        }
      }
    }
    .terms_individual_details {
      dt {
        color: $secondary-color;
        font-size: 1.5rem;
        padding: 0.8rem 0;
        padding-top: 2rem;
        font-weight: 700;
      }
      .sub_detail_title {
        color: $secondary-color;
        font-size: 1rem;
        font-weight: 700;
        span {
          font-weight: 500;
        }
      }
      dd {
        color: $secondary-color;
        font-size: 1rem;
        font-weight: 200;
        b {
          font-weight: 700;
        }
      }
      ol.steps_ordered_list {
        li:before {
          content: "Step ";
          font-weight: 500;
        }
      }

      .abcd_ordered_list {
        padding-bottom: 0.8rem;
        font-weight: 200;
        li {
          color: $secondary-color;
          font-size: 1rem;
          font-weight: 200;
        }
      }
      ol.normal_ordered_list {
        padding-bottom: 0.8rem;
        list-style-type: disc;
        font-weight: 200;
        li {
          color: $secondary-color;
          font-size: 1rem;
          font-weight: 200;
        }
      }
      ol {
        padding-bottom: 0.8rem;
        li {
          color: $secondary-color;
          font-size: 1rem;
          font-weight: 200;
        }
      }
      ul {
        padding-bottom: 0.8rem;
        li {
          color: $secondary-color;
          font-size: 1rem;
          font-weight: 200;
        }
      }
    }
  }
  .red_text_color {
    color: $error !important;
  }
  .yellow_highlight {
    background-color: #fbe739;
  }
  .phone_link {
    color: $primary-color;
    font-weight: 600;
  }

  @media only screen and (max-width: 1340px) {
    .page_container {
      padding: 0 2.5rem;
    }
  }
  @media only screen and (max-width: 768px) {
    .main {
      flex-direction: column !important;
    }
    .terms__title {
      font-size: 2rem;
    }
    .page_container {
      padding: 0 2.5rem;
    }
    .container-divider {
      ul {
        padding-left: 0;
        .icon {
          font-size: 1.5rem;
        }
      }
    }
    .colored_ordered_list {
      column-count: 1 !important;
    }
  }

  @media only screen and (max-width: 540px) {
    .terms__title {
      font-size: 2rem !important;
    }
    .service__title--center {
      font-size: 1.5rem !important;
    }
    .terms_individual_details {
      dt {
        font-size: 1.2rem !important;
      }
    }
    .container-divider .icon {
      font-size: 1.2rem !important;
    }
  }

  @media screen and (max-width: 425px) {
    .main {
      margin-top: 0;
    }
    .service__title {
      font-size: 1.5rem;
    }
    .page_container {
      padding: 0 1.5rem;
    }
    .terms__title {
      font-size: 1.5rem !important;
    }
    .service__title--center {
      font-size: 1.2rem !important;
    }
    .terms_individual_details {
      overflow: auto;
      dt {
        font-size: 1rem !important;
      }
    }
    .terms_table {
      tr {
        th {
          padding: 0rem 0.5rem;
        }
        td {
          padding: 0rem 0.5rem;
          font-weight: 200;
        }
      }
    }
    .container-divider .icon {
      font-size: 0.9rem !important;
    }
  }

  @media screen and (max-width: 375px) {
    .terms__description,
    .main {
      padding: 1rem;
    }
  }

  @media only screen and (max-width: 320px) {
    .page_container {
      padding: 0 1rem !important;
    }
    .terms__title {
      font-size: 1.2rem !important;
    }
    .service__title--center {
      font-size: 1rem !important;
    }
  }
}

@media only screen and (max-width: 120px) {
  #terms-page .terms__container {
    flex-direction: column;
  }
}
