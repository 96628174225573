
.form_wrapper {
    padding: 1rem;
    width: 65%;
    .form_row {
        width: 100%;
        display: flex;
        input, select ,textarea{
            width: 100%;
        }
        textarea {
            min-height: 5rem;
        }
        .form_col, .form_col-2, .form_col-3 {
            padding: .5rem 1rem;
            margin: 0;
        }

        .form_col {
            max-width: 100%;
            width: 100%;
        }
        .form_col-2 {
            max-width: 50%;
        }
        .form_col-3 {
            max-width: calc(100%/3);
        }
    }
}