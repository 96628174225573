#contact-page {
  background-image: url("/imgs/hero/hero_mobile.png");
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  // filter: opacity(0.7);
  padding: 10px;
  .page_container {
    margin-top: 0;
  }
}
.contact-page {
  &__container {
    top: 2%;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    background: rgba(255, 255, 255, 0.5);
  }
  &__title {
    color: $indigo;
    font-size: 3rem;
    font-weight: 200;
    line-height: 2.3;
    text-align: center;
  }
  &__phone {
    font-size: 1.5rem;
    font-weight: 700;
    color: #67c4d0;
    float: right;
    display: flex;
    margin-right: 15px;

    i {
      margin-right: 15px;
    }
  }
}
.contact-form {
  @include makeGrid(space-between, center);
  width: 100%;

  img {
    width: 90%;
  }

  .contact-form__container {
    width: 55%;

    .f-center {
      justify-content: flex-start;
      width: 100%;
    }

    .input-label__container {
      margin: 5px 15px;
      width: 50%;

      .error {
        color: red;
        font-weight: 200;
        font-size: 0.9rem;
      }
    }

    label {
      margin-right: 10px;
      font-weight: 700;
      color: #424242;
      margin-bottom: 0;
    }
    input {
      width: 100%;
      background: #fff;
      color: $secondary-color;
      border: #b8b8b8 solid 2px;
      padding: 5px;
      border-radius: 5px;

      &:active {
        background: #fff;
      }
    }

    .service {
      display: flex;
      justify-content: center;
      flex-direction: column;

      select {
        background: #fff;
        width: 100%;
        color: #5d5e5e;
        border: #b8b8b8 solid 2px;
        padding: 5px;
        border-radius: 5px;
        width: 95%;
      }
    }
    .other-input {
      height: 40px;
      border-radius: 5px;
      width: 100%;
      padding: 5px 10px;
      border: #b8b8b8 solid 2px;
      color: $secondary-color;
      width: 95%;
    }
    .comment {
      height: 150px;
      width: 100%;
      border-radius: 5px;
      padding: 5px 10px;
      border: #b8b8b8 solid 2px;
      color: $secondary-color;
      width: 95%;
    }
    .submit-btn {
      padding: 10px 35px;
      background: $primary-color;
      color: #fff;
      border-radius: 5px;
      border: none;

      &:hover {
        background: $light-blue;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .contact__image {
    display: none;
  }
  .contact-form__container {
    width: 100% !important;
    .f-center {
      .input-label__container {
        width: 50%;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .contact-page__title {
    line-height: 1.2 !important;
    font-size: 2.5rem;
  }
  .contact-page__phone {
    font-size: 1.5rem;
    text-align: center;
    justify-content: center;
    width: 100%;
    i {
      font-size: 1.5rem;
      margin: auto 0.5rem;
    }
  }
  .contact-form__container {
    width: 100% !important;
    .f-center {
      flex-direction: column;
      padding: 0 15px;
      .input-label__container {
        width: 100%;
      }
    }
  }
}
@media only screen and (max-width: 425px) {
  .dialog {
    padding: 0.8rem !important;
    margin: 0.8rem !important;
  }
  .contact-page__title {
    line-height: 1.2 !important;
    font-size: 2rem;
  }
  .contact-page__phone {
    font-size: 1rem;
    text-align: center;
    justify-content: center;
    width: 100%;
    i {
      font-size: 1rem;
      margin: auto 0.5rem;
    }
  }
  .f-center {
    padding: 0 5px !important;
  }
  .input-label__container {
    margin: 5px !important;
    label {
      font-weight: 400 !important;
    }
  }
}
@media only screen and (max-width: 320px) {
  .dialog {
    padding: 0.5rem !important;
    margin: 0.5rem !important;
  }
  .contact-page__title {
    line-height: 1.2 !important;
    font-size: 1.5rem;
  }
  .contact-page__phone {
    font-size: 0.8rem;
    text-align: center;
    justify-content: center;
    width: 100%;
    i {
      font-size: 0.8rem;
      margin: auto 0.5rem;
    }
  }
  .input-label__container {
    margin: 5px !important;
    label {
      font-weight: 400 !important;
    }
  }
}
