.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  // background: #fff;
}

// Bannerrrrr //
.banner {
  padding: 0;
  width: 100%;
  text-align: center;
  background-color: #4cc3db;
  margin: 0;

  p {
    color: #fff;
    padding: 0.5rem;
    font-weight: 600;
    margin-bottom: 0;
    span {
      font-style: italic;
      text-decoration: underline;
      a {
        color: #fff;
      }
    }
  }
}

#nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 40px;
  transition-duration: 0.1s;

  .logo {
    transition: 0.4s;

    a #img-logo {
      opacity: 1!important;
      cursor:pointer!important;
    }
  }

  .nav__link {
    list-style-type: none;
    padding: 10px 0;
    transition-duration: 0.5s;
    position: relative;
    display: block;
    float: left;
    cursor: pointer;

    a {
      text-decoration: none;
      color: $primary-color;
      padding: 8px 15px;
      font-weight: 200;

      // &:hover {
      //     background: $primary-color;
      //     color: #fff;
      //     border-radius: 5px;
      // }
    }
    &:hover {
      background: $primary-color;
      color: #fff;
      border-radius: 5px;
    }
  }

  .nav__ul {
    display: flex;
    justify-content: center;

    // nav__ul--li
    .nav__link--list {
      list-style: none;
      padding: 10px 15px;
      transition-duration: 0.5s;
      position: relative;
      display: block;
      float: left;
      color: $primary-color;
      font-weight: 200;

      a {
        text-decoration: none;
      }
      &:hover {
        background: $primary-color;
        color: #fff;
        border-radius: 5px;
        text-decoration: none;
      }
    }
    .nav__link-btn {
      list-style: none;
      padding: 10px 15px;
      transition-duration: 0.5s;
      position: relative;
      display: block;
      float: left;
      color: $primary-color;
    }
    .dropdown {
      position: relative;
      display: inline-block;

      &:hover a {
        color: #fff;
        border-radius: 5px 5px 0 0;
      }

      .dropdown-content {
        display: none;
        position: absolute;
        background-color: $primary-color;
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
        border-radius: 0 6px 6px 6px;
        width: 250px;
        top: 40px;

        a {
          float: none;
          color: #fff;
          text-decoration: none;
          display: block;
          text-align: left;
          font-size: 0.9rem;
          transition: all 0.5s ease;

          &:hover {
            background: $light-blue;
            border-radius: 0 !important;
            color: #fff;
            border-radius: 0px;
          }
        }
        .active {
          background: $light-blue;
          border-radius: 0 !important;
        }
      }
      ul li .dropdown-content:hover {
        visibility: visible;
        opacity: 1;
        display: block;
      }
    }
    .dropdown:hover .dropdown-content {
      display: block;
    }
  }
  #login {
    text-decoration: none;
    color: #fff;
    background: $green;
    padding: 10px 20px;
    border-radius: 10px;
    margin-left: 10px;
  }
  .burger {
    display: none;

    div {
      width: 25px;
      height: 3px;
      background-color: #cccc;
      margin: 5px;
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
    }
  }
}
.burger-active {
  background: $primary-color;
  color: #fff;
}
.show-nav {
  display: flex !important;
}
ul {
  margin: 0 auto !important;
}
.arrow {
  border: solid #9b9999;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  margin-left: 8px;
  z-index: 1;
  margin-bottom: 3px;
  transform: rotate(-45deg);
}
.rotate {
  transform: rotate(45deg);
}
.active {
  background: $primary-color;
  border-radius: 5px;
  color: #fff !important;

  a {
    color: #fff !important;
  }

  // &:before {
  //     content:"";
  //     position: absolute;
  //     bottom: 0;
  //     width: 100%;
  //     color: #fff;
  // }
}
.dropdown-active {
  background: $light-blue !important;
  border-radius: 0 !important;
}

.nav-links li,
.nav__link--list {
  list-style: none;
}

.burger {
  display: none;
}

.burger div {
  width: 25px;
  height: 3px;
  background-color: #e2e2e2;
  margin: 5px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

#nav-drawer {
  display: none;

  #aside-nav-links {
    .nav-drawer__link a {
      color: $primary-color;
      font-weight: 400;
    }

    li {
      margin: 5px 0px 25px 20px;
      color: $primary-color;
      font-weight: 700;

      #services-tab,
      #industries-tab {
        font-weight: 700;
      }
    }

    .logo-2 {
      padding: 10px 0;
      border-bottom: 1px solid #ccc;

      a.active {
        background: transparent !important;
      }
    }

    .dropdown {
      position: relative;
      display: inline-block;

      .dropdown-content {
        background-color: #fff;
        min-width: 160px;
        z-index: 3;
        border-radius: 6px;
        width: 250px;
        display: none;
        flex-direction: column;

        a {
          color: $primary-color;
          text-decoration: none;
          padding: 5px 10px;
          text-align: center;
        }
        .active {
          background: $light-blue;
          border-radius: 0 !important;
        }
      }
      .label-align {
        @include makeGrid(center, center);
      }
    }
    .nav-drawer__link--list {
      margin-bottom: 25px;
      margin-top: 10px;

      a {
        padding: 10px;
        color: $primary-color;
        text-decoration: none;
      }
    }
    #login {
      margin-left: 0;
    }
    .nav-drawer__link-btn {
      margin-top: 10px;
      margin-bottom: 100px;
    }
  }
}
.nav-drawer__ul .nav-drawer__link a.active {
  background: $primary-color !important;
  color: #fff;
}

//======================= //
// ==== MEDIA QUERIES === //
//======================= //

@media only screen and (max-width: 1098px) {
  #nav {
    .nav__ul {
      font-size: 0.9rem;
    }
    .logo a #img-logo {
      width: 220px !important;
    }
  }
}
@media (max-width: 1115px) {
  #nav {
    .nav__ul {
      font-size: 0.9rem;
    }
    .logo a #img-logo {
      width: 220px !important;
    }
  }
}
@media (max-width: 1018px) {
  #nav {
    .nav__ul {
      font-size: 0.9rem;
    }
    .logo a #img-logo {
      width: 210px !important;
    }
  }
}
@media (min-width: 1004px) {
  #aside-nav-links {
    display: none;
  }
}
@media only screen and (max-width: 1004px) {
  #nav .nav__ul {
    display: none;
  }
  #aside-nav-links {
    display: flex !important;
  }
  .logo-2 {
    visibility: hidden;
  }
  .nav-links {
    width: 50%;
    position: absolute;
    right: 0;
    top: 0;
    height: 100vh;
    overflow-y: scroll;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;

    li {
      opacity: 1;
    }
  }
  .burger {
    display: block !important;
    z-index: 3;
  }
  .dropdown {
    z-index: 1;
  }
  .dropdown-content {
    left: -50%;
  }
}
@media only screen and (max-width: 600px) {
  .nav-links {
    width: 100%;
    margin-top: 0;
  }
  .logo-2 {
    visibility: visible;

    > a img {
      width: 200px;
    }
  }
}
