
/*
Theme: DNA Micro Template
Author: Hannah Jade Carungay
URL: http://www.dnamicro.com
Â© 2020 DNA Micro Software Solutions - All Rights Reserved
*/


// libaries 
@import "~bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');


@import 'utils';
@import 'pages';
@import 'common';
@import 'components';