.hero {
  width: 100%;
  height: 100vh;
  position: relative;
  background-size: cover;
  flex-direction: column;
  background-position: 50% 90%;
  background-repeat: no-repeat;
  @include makeGrid(flex-start, center);
  background-image: url("/imgs/hero/mountain.png");

  [data-aos^="fade"][data-aos^="fade"].aos-animate {
    opacity: 0 !important;
  }
  [data-aos^="fade"][data-aos^="fade"] {
    opacity: 1 !important;
  }

  &_logo {
    margin-top: 15rem;
  }

  &_title {
    width: 90%;
    color: $indigo;
    font-size: 2.5rem;
    font-weight: 200;
    margin-top: 2.75em;
    text-align: center;
  }

  // .hero_buildings
  &_buildings {
    width: 100%;
  }

  .building {
    bottom: 0;
    width: 100%;
    width: 100%;
    position: absolute;
  }

  .industry {
    z-index: 10;
    margin-top: auto;
    position: absolute;

    &:hover {
      transform: scale(1.02);
      transition: ease-in-out 0.5s;
    }

    &.airport {
      width: 10%;
      left: 8.9%;
      bottom: 0;
    }

    &.medical {
      width: 11.4%;
      bottom: 0;
      left: 17.6%;
    }

    &.government {
      width: 9.2%;
      bottom: 0;
      left: 28.7%;
    }

    &.law {
      width: 13.35%;
      bottom: 0;
      left: 36.67%;
    }

    &.corporate {
      width: 8.4%;
      left: 48.1%;
      bottom: 0;
    }

    &.finance {
      width: 12.9%;
      bottom: 0;
      left: 52.45%;
    }

    &.construction {
      width: 15.3%;
      bottom: 0;
      left: 72.4%;
    }

    &.retail {
      width: 9.6%;
      bottom: 0%;
      left: 65.3%;
    }

    &.manufacturing {
      width: 29.78%;
      bottom: 0;
      left: 71.3%;
    }
  }
}

@media (max-width: 1024px) {
  .hero {
    background-image: url("/imgs/hero/hero_mobile.png");

    // .hero_buildings
    &_buildings {
      display: none;
    }
  }
}

@media (max-width: 1024px) and (max-height: 768px) {
  .hero .hero_logo {
    margin-top: 6rem;
  }
}

@media (max-height: 754px) {
  .hero .hero_logo {
    margin-top: 7rem;
  }
}

@media (max-width: 426px) {
  .hero {
    .hero_logo {
      margin-top: 12rem;
    }

    .hero_title {
      font-size: 2rem;
      margin-top: 3.25em;
    }
  }
}

@media (max-height: 630px) {
  .hero .industry {
    display: none;
  }
}
